import React from "react"
import { Container, Box } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import GiftcardItem from "./GiftcardItem"
import Title from "@app/components/Sections/Title/Title"

const Giftcard = ({ page }) => {
  const content = useContent(page)
  return (
    <>
      <Title title={page?.title} />
      <Container maxW="lg" pb={16} px={[6, 4]}>
        <Box as="section" pb={8} textAlign="center">
          {content}
        </Box>
        <GiftcardItem />
      </Container>
    </>
  )
}

export default Giftcard
