import { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useCheckout } from "@app/hooks/useCheckout"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"

export const useGiftcard = () => {
  const {
    settings: { params },
  } = useConfigContext()
  const { checkout } = useCheckoutContext()
  const { applyGiftCardCode } = useCheckout()
  const {
    helpers: { decodeBase64, getUrlParameter },
  } = useCore()
  const { formatMoney } = useShopify()
  const location = useLocation()

  const [card, setCard] = useState(null)
  const [errors, setErrors] = useState<Array<string>>([])
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const getCard = () => {
    const rawGiftcard = getUrlParameter(params?.giftcard, location)

    try {
      const card = rawGiftcard && JSON.parse(decodeBase64(rawGiftcard))

      if (card?.code)
        setCard({
          ...card,
          balance: {
            initial: formatMoney(card?.balance?.initial),
            remaining: formatMoney(card?.balance?.remaining),
          },
          code: {
            partial: card?.code?.slice(-4)?.padStart(card?.code?.length, "*"),
            full: card?.code,
          },
          enabled: card?.enabled && !card?.expired,
          expires: card?.expires && new Date(card?.expires),
        })
    } catch (error) {
      setErrors([error?.message])
    }
  }

  const handleApply = async (event: any) => {
    event.preventDefault()

    setErrors([])
    setLoading(true)
    //@ts-ignore
    const cards = await applyGiftCardCode(card?.code?.full)
    setLoading(false)

    if (cards?.checkoutUserErrors?.length > 0) {
      setErrors(cards.checkoutUserErrors.map(({ message }: { message: any }) => message))
    }
  }

  const handleShow = (event: any) => {
    event.preventDefault()

    setShow(active => !active)
  }

  useEffect(() => {
    getCard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applied = checkout?.appliedGiftCards?.find(({ lastCharacters }: { lastCharacters: any }) =>
    card?.code?.full?.toLowerCase()?.includes(lastCharacters?.toLowerCase())
  )

  return {
    applied,
    card,
    errors,
    loading,
    show,
    handleApply,
    handleShow,
  }
}
